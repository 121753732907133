import "../styles/leistungen.css"

import { Col, Container, Image, Row } from "react-bootstrap"

import Fade from "react-reveal/Fade"
import MultiLine from "./multiLine"
import React from "react"

export default props => (
  <div>
    <div className={"arrow-up " + props.Color} />
    <div className={"contentbox leistungen " + props.Color}>
      <Fade left={!props.IsImageLeft} right={props.IsImageLeft}>
        {props.IsImageLeft ? (
          <Container className="text-left">
            <Row className="d-flex align-items-center">
              <Col sm={12} md={true} className="leistungen-nopadding">
                <Image src={props.Image} fluid />
                {props.ImageSource  &&
                  <div className="text-center">
                    (Fotoquelle: {props.ImageSource})
                  </div>
                }
              </Col>
              <Col sm={12} md={true}>
                <h5>{props.Title}</h5>
                <p>
                  <MultiLine Text={props.Content} />
                </p>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container className="text-left">
            <Row className="d-flex align-items-center">
              <Col sm={12} md={true}>
                <h5>{props.Title}</h5>
                <p>
                  <MultiLine Text={props.Content} />
                </p>
              </Col>
              <Col sm={12} md={true} className="leistungen-nopadding">
                <Image src={props.Image} fluid />
                {props.ImageSource  &&
                  <div className="text-center">
                    (Fotoquelle: {props.ImageSource})
                  </div>
                }
              </Col>
            </Row>
          </Container>
        )}
      </Fade>
    </div>
  </div>
)
