import React from "react"

export default props => (
  <div>
    {props.Text.split("<p />").map((paragraph, keyP) => {
      return (
        <p key={keyP}>
          {paragraph.split("<br />").map((newLine, keyL) => {
            return <div key={keyL}>{newLine}</div>
          })}
        </p>
      )
    })}
  </div>
)
