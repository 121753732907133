import React from "react"
import Header from "../../components/header"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import Footer from "../../components/footer"
import ContactBox from "../../components/boxContact"

import imgFensterVelux01 from "../../assets/leistungen/velux01.jpg"
import imgFensterVelux02 from "../../assets/leistungen/velux02.jpg"
import imgFensterRoto01 from "../../assets/leistungen/roto01.jpg"
import imgFensterRoto02 from "../../assets/leistungen/roto02.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung
      Title="Dachfenster von VELUX und ROTO"
      Content="Wir empfehlen dafür Fenster der Hersteller VELUX und ROTO. Diese&nbsp; 
        hochwertigen Fenster setzten wir nicht nur beim Neubau ein,&nbsp; 
        sondern verwenden sie auch beim nachträglichen Einbau in&nbsp; 
        vorhandene Dachgeschosse. Auf Wunsch montieren wir außerdem das&nbsp; 
        passende Zubehör wie etwa Rollläden, Rollos und Markisen."
    />
    <LeistungBox
      Color="gray"
      Image={imgFensterVelux01}
      IsImageLeft={true}
      Title="VELUX Dachfenster"
      Content="Sie wollen die alten Fenster im Dach austauschen? Oder Ihren&nbsp; 
        Dachboden in einen Wohnraum verwandeln? Oder sogar ein neues Haus&nbsp; 
        bauen? Dann finden Sie hier genau die passende VELUX Fensterlösung&nbsp; 
        für Ihre Wünsche – vom Standardfenster bis zur hochwertigen&nbsp; 
        Lichtlösung."
    />
    <LeistungBox
      Color="white"
      Image={imgFensterVelux02}
      IsImageLeft={false}
      Title="VELUX Zubehör"
      Content="Die Auswahl an VELUX-Zubehör umfasst Produkte zur Bedienung,&nbsp; 
        Sicherheit sowie zur Pflege und Wartung Ihrer Fenster und&nbsp; 
        Sonnenschutzelemente."
    />
    <LeistungBox
      Color="gray"
      Image={imgFensterRoto01}
      IsImageLeft={true}
      Title="ROTO Dachfenster"
      Content="Diese Fenster überzeugen durch hervorragende Wärmedämmwerte, beste&nbsp; 
        Verarbeitung und klares Design. Aus unserer Sicht sind Sie der&nbsp; 
        Inbegriff für Premiumqualität „made in Germany“."
    />
    <LeistungBox
      Color="white"
      Image={imgFensterRoto02}
      IsImageLeft={false}
      Title="ROTO Zubehör"
      Content="ROTO bietet Ihnen ein umfangreiches Sortiment an Rollläden,&nbsp; 
        Rollos und Markisen zum Schutz vor Sonne, Hitze und Kälte,&nbsp; 
        dekorative Innenfutter in geschmackvollen Designs sowie&nbsp; 
        Steuerungen und Fensterantriebe für ROTO Wohndachfenster."
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
