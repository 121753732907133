import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import MultiLine from "./multiLine"

import "../styles/leistungen.css"

export default props => (
  <div className="contentbox white">
    <Container className="text-center">
      <Row>
        <Col sm={12} md={true}>
          <h1>{props.Title}</h1>
          <hr />
          <div className="text-left">
            <MultiLine Text={props.Content} />
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)
